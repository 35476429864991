/** @jsx jsx */
import { jsx } from "theme-ui"

const SunIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="40" width="40" viewBox="0 0 26 26" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
     );
}

export default SunIcon;
