/** @jsx jsx */
import { jsx } from "theme-ui"

const MoonIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="-2 0 24 24" fill="currentColor">
            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
        </svg>
     );
}

export default MoonIcon;
